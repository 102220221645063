import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import ogpDefaultImg from "../images/ogp.png"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, ogp, pageUrl, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata?.description
  const metaOgp = ogp || ogpDefaultImg
  const metaURL = "https://" + pageUrl || site.siteMetadata?.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata?.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaURL,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: 'https://mashingup.org' + metaOgp,
        },
        {
          property: `fb:app_id`,
          content: `1500416890266187`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: 'https://mashingup.org' + metaOgp,
        },
        {
          name: `keywords`,
          content: keywords.join(`, `),
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `ja`,
  meta: [],
  keywords: [],
  description: ``,
  title: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}
export default Seo
